











































































































































import {
  defineComponent, ref, useContext, useRouter, watch,
} from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { IBlokSingleImage } from '~/components/storyblok/SingleImage.vue';
import SfCarousel from '~/components/StorefrontUi/SfCarousel.vue';
import { addBasePath } from '~/helpers/addBasePath';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { resolveUrl } from '~/modules/storyblok/helpers/resolveUrl';

interface ISlide {
  _uid: string;
  banner: IBlokSingleImage[];
  link: {
    url: string;
  };
}

interface IBlok {
  _uid: string;
  slide: ISlide[];
}

export default defineComponent({
  name: 'SliderMainBlok',
  components: {
    SfCarousel,
    SkeletonLoader,
    Modal: () => import('~/components/Modal.vue'),
  },
  props: {
    blok: {
      type: Object as PropType<IBlok>,
      required: true,
    },
  },
  setup({ blok }: { blok: IBlok }) {
    const { app } = useContext();
    const router = useRouter();
    const carouselRef = ref(null);
    const activeIndex = ref(0);
    const isModalOpen = ref(false);
    const viewedBanners = ref([]);

    const goToSpecificSlide = (slideId: number) => {
      carouselRef.value.goToSlide(slideId + 1);
    };

    const closeModal = () => {
      isModalOpen.value = false;
    };

    watch(
      // eslint-disable-next-line consistent-return
      () => {
        if (carouselRef.value && carouselRef.value.glide) {
          return carouselRef.value.glide.index;
        }
      },
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          activeIndex.value = newVal;
        }
      },
    );

    const getBannerForDL = (banner, order) => {
      const {
        promotionName, promotionStart, promotionEnd, _uid: id,
      } = banner;
      const alt = banner?.banner[0]?.Alt || '';
      const url = banner.link.cached_url as string;
      return {
        promotion_name: promotionName,
        banner: {
          id,
          from: promotionStart,
          to: promotionEnd,
          urlDesktop: url,
          alt,
          order,
        },
      };
    };

    const pushToUrl = (banner, order) => {
      const url = resolveUrl(banner.link.cached_url as string);
      const bannerForDL = getBannerForDL(banner, order);
      app.$googleTagManager.promoClick(bannerForDL);

      const isStoryLink = banner.link.linktype === 'story';
      if (!isStoryLink) {
        window.open(url, '_blank');
      } else {
        const isHomePageUrl = url === 'home';
        const pushUrl = isHomePageUrl ? '/' : `/${decodeURIComponent(url)}`;
        router.push(pushUrl);
      }
    };

    const { slide } = blok;
    const isSingleSlide = slide.length === 1 && slide[0].banner.length === 1;
    const noSlides = !blok || !slide || slide?.length === 0;

    const onVisibilityChange = (isVisible, entry, banner, index) => {
      // eslint-disable-next-line no-underscore-dangle
      const bannerUid = banner._uid;
      if (isVisible && !viewedBanners.value.includes(bannerUid)) {
        app.$googleTagManager.promo(getBannerForDL(banner, index));
        viewedBanners.value.push(bannerUid);
      }
    };

    return {
      onVisibilityChange,
      slide,
      pushToUrl,
      carouselRef,
      activeIndex,
      isSingleSlide,
      goToSpecificSlide,
      noSlides,
      // Modal
      isModalOpen,
      closeModal,
    };
  },
  methods: { addBasePath },
});
